<template>
  <v-row v-if="festival.acf">
    <v-col cols="12" md="8">
      <h2 class="text-h5 primary--text mb-8 text-uppercase">
        {{ formatDate(festival.acf?.start_date, festival.acf?.end_date) }}
      </h2>
      <p class="text-body-2 mb-12" v-html="festival.content?.rendered"></p>

      <!-- <h3 class="text-h5 primary--text mb-4 text-uppercase">Billetterie et adhésion (saison 2022 - 2023)</h3> -->
      <!-- <h5 class="text-subtitle-2 mt-5 font-weight-bold">Billetterie et adhésion (saison 2022 - 2023)</h5> -->

      <!-- <v-row class="mb-5">
        <v-col cols="5">
          <v-btn
            block
            rounded
            class="secondary mt-2"
            elevation="2"
            x-large
            href="https://www.billetweb.fr/pro/billetterie-festival-troc"
            target="_blank"
          >réserver</v-btn>
        </v-col>
        <v-col cols="5">
          <v-btn
            text
            color="primary"
            :to="{ name: 'politique' }"
            class="pt-12"
          >
            politique de billetterie
          </v-btn>
        </v-col>
      </v-row> -->

      <!-- <p class="text-caption">Ouverture de la billetterie début Juillet</p> -->
      <!-- <ul class="text-body-2 list">
        <li>Tarif plein : 10 euros</li>
        <li>Tarif réduit (Adhérents, - de 18 ans) : 5 euros</li>
        <li>Gratuit - de 7 ans (spectacle du soir) : 10 euros</li>
        <li>Gratuit - de 3 ans (spectacle 11h) : 10 euros</li>
      </ul> -->

      <!-- <h5 class="text-subtitle-2 mt-5 font-weight-bold">Adhésion</h5>
      <p class="text-caption">Adhésion saison 2022 / 2023</p> -->
      <!-- <ul class="text-body-2 list">
        <li>Tarif plein : 10 euros</li>
        <li>Tarif réduit (demandeurs du RSA, étudiants) : 5 euros</li>
      </ul> -->
    </v-col>
    <!-- <v-col cols="12">
      <ComingMaps
        v-bind:informations="comingIcons"
        v-bind:lattitude="44.9339"
        v-bind:longitude="0.4798"
        v-bind:title="`Venir au festival`"
        v-bind:marker="true"
      />
    </v-col> -->
    <v-col cols="12" v-if="pastFestival.length > 0">
      <TitleAppBar
        v-bind:title="'Précédentes éditions'"
        v-bind:littlePadding="true"
      />
      <FestivalSlider v-bind:sliders="pastFestival" />
    </v-col>
    <v-col cols="12" v-if="actuFestival.length > 0" class="mb-5 mt-10">
      <TitleAppBar
        v-bind:title="'Actualités associées'"
        v-bind:littlePadding="true"
      />
      <v-row>
        <v-col
          cols="12"
          sm="6"
          md="4"
          xl="3"
          v-for="actu in actuFestival"
          :key="actu.id"
        >
          <ActuFestivalCard
            v-bind:slug="actu.slug"
            v-bind:id="actu.id"
            v-bind:title="actu.title?.rendered"
            v-bind:imgUrl="actu.acf?.image"
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// import ComingMaps from "../../../components/maps/comingMaps";
import ActuFestivalCard from "../../../components/cards/actuFestivalCard";
// import TitleAppBar from "../../../components/titleAppBar";
// import FestivalSlider from "../../../components/slider/festivalSlider";
import { APIService } from "../../../APIService";
const apiService = new APIService();
import moment from "moment";

export default {
  name: "Info",

  components: {
    // ComingMaps,
    // TitleAppBar,
    ActuFestivalCard
    // FestivalSlider
  },

  data: () => ({
    // comingIcons: [
    //   { icon: "positionYellow", text: "La Tocade, Le Bas Bourg 24140 Maurens" },
    //   {
    //     icon: "parkingYellow",
    //     text: "En bas du village en approche de la départementale D4/E3"
    //   },
    //   {
    //     icon: "homeYellow",
    //     text:
    //       '<a href="https://www.pays-bergerac-tourisme.com/fr" target="_blank" class="font-weight-bold font-italic black--text">consultez le site de l\'office de tourisme de Bergerac</a>'
    //   }
    // ],
    actuFestival: [],
    pastFestival: [],
    festival: [],
    numberFestivalActu: 3
  }),

  mounted() {
    this.onResize();
    // apiService.getAll("acf/v3/options/options").then(data => {
    //   this.festival = data
    // });
    apiService.getAll("wp/v2/festival").then(data => {
      let today = moment();
      this.festival = data.filter(fest =>
        moment(fest.acf.start_date, "DD/MM/YYYY").isAfter(today)
      )[0];
      this.pastFestival = data.filter(fest =>
        moment(fest.acf.start_date, "DD/MM/YYYY").isBefore(today)
      );
    });
    apiService.getAll("wp/v2/posts").then(data => {
      this.actuFestival = data.filter(actu => actu.acf.article_lie_au_festival);
    });
  },

  methods: {
    dateFormat(data, format) {
      return moment(String(data), "DD/MM/YYYY")
        .locale("fr")
        .format(format);
    },
    formatDate(start, end) {
      if (this.dateFormat(start, "MMMM") === this.dateFormat(end, "MMMM")) {
        return (
          "Du " +
          this.dateFormat(start, "D") +
          " au " +
          this.dateFormat(end, "D MMMM YYYY")
        );
      } else {
        return (
          "Du " +
          this.dateFormat(start, "D MMMM") +
          " au " +
          this.dateFormat(end, "D MMMM YYYY")
        );
      }
    },

    onResize() {
      const width = window.innerWidth;
      if (width < 600) {
        this.numberFestivalActu = 1;
      } else if (width >= 600 && width < 1264) {
        this.numberFestivalActu = 2;
      } else if (width >= 1264 && width < 1904) {
        this.numberFestivalActu = 2;
      } else if (width > 1904) {
        this.numberFestivalActu = 3;
      } else {
        this.numberFestivalActu = 3;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.list {
  list-style: inside ". ";
  padding: 0;
}
</style>
