<template>
  <v-container>
    <TitleAppBar v-bind:title="'Spectacles'" />

    <v-container>
      <v-row>
        <v-col v-for="play in plays" :key="play.id" cols="12" sm="6" lg="4">
          <PlayCard
            v-bind:title="play.title.rendered"
            v-bind:text="play.content.rendered"
            v-bind:information="play?.acf?.information"
            v-bind:slug="play.slug"
            v-bind:id="play.id"
            v-bind:imgUrl="play?.acf?.image"
          />
        </v-col>
      </v-row>
    </v-container>

    <div v-if="othersPlays.length > 0">
      <TitleAppBar v-bind:title="'Compagnies invitées'" />
    </div>

    <v-container>
      <v-row>
        <v-col v-for="play in othersPlays" :key="play.id" cols="12" sm="6" lg="4">
          <PlayCard
            v-bind:title="play.title.rendered"
            v-bind:text="play.content.rendered"
            v-bind:information="play?.acf?.information"
            v-bind:slug="play.slug"
            v-bind:id="play.id"
            v-bind:imgUrl="play?.acf?.image"
          />
        </v-col>
      </v-row>
    </v-container>

    <div v-if="archivePlays.length > 0">
      <TitleAppBar v-bind:title="'Anciens Spectacles'" />
    </div>

    <v-container>
      <v-row>
        <v-col
          v-for="play in archivePlays"
          :key="play.id"
          cols="12"
          sm="6"
          lg="4"
        >
          <PlayCard
            v-bind:title="play.title.rendered"
            v-bind:text="play.content.rendered"
            v-bind:information="play?.acf?.information"
            v-bind:slug="play.slug"
            v-bind:id="play.id"
            v-bind:imgUrl="play?.acf?.image"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import PlayCard from "../../../components/cards/playCard";
import TitleAppBar from "../../../components/titleAppBar";
import { APIService } from "../../../APIService";
const apiService = new APIService();

export default {
  name: "AboutPlay",

  components: {
    PlayCard,
    TitleAppBar
  },

  data: () => ({
    plays: [],
    othersPlays: [],
    archivePlays: []
  }),

  mounted() {
    apiService.getAll("wp/v2/plays").then(data => {
      data.forEach(d => {
        if (d?.acf?.display == "display") {
          this.plays.push(d);
        } else if (d?.acf?.display == "invite") {
          this.othersPlays.push(d)
        } else if (d?.acf?.display == "catalogue"){
          this.archivePlays.push(d);
        }
      });
    });
  }
};
</script>