<template>
  <div class="upOthers">
    <v-alert class="color white--text secondary">
      <v-container class="pt-0 pb-0">
        Devenez mécènes : soutenez le futur du TROC ! <a href="https://www.helloasso.com/associations/theatre-du-roi-de-coeur/formulaires/5">en cliquant ici</a>
      </v-container>
    </v-alert>

    <ContainerBackgroundGrey
      v-bind:title="bannerFestival.titleRewriting"
      v-bind:subTitle="bannerFestival.excerpt.rendered"
      v-bind:btnTitle="ticketOpen ? 'réserver' : 'en savoir plus'"
      v-bind:imgUrl="bannerFestival?.acf?.image"
      v-bind:principal="true"
      v-bind:urlItem="true"
      v-bind:url="{ slug: bannerFestival.slug, id: bannerFestival.id }"
      v-bind:secondUrl="'festival'"
    />

    <v-container class="bigPadding">
      <v-row align="center">
        <v-col cols="12" sm="6" class="pr-10">
          <div>
            <v-img
              height="500"
              width="100%"
              :src="bannerActu?.acf?.image"
            ></v-img>
          </div>
        </v-col>
        <v-col cols="12" sm="6" align-justify="center" class="pl-10">
          <div class="text-center text-sm-left">
            <h3 class="text-h2" v-text="bannerActu.titleRewriting"></h3>
            <BlackLine />
            <p
              class="mt-2 mb-10"
              v-html="
                `${returnText(bannerActu.excerpt.rendered)}
                  <a 
                    href='
                      ${
                        $router.resolve({
                          name: 'actuItem',
                          params: { slug: bannerActu.slug, id: bannerActu.id }
                        }).href
                      }
                    '
                    class='blue--text font-weight-medium font-italic'
                  >lire la suite</a>`
              "
            ></p>

            <div>
              <v-btn
                depressed
                outlined
                plain
                rounded
                :href="
                  $router.resolve({
                    name: 'actuItem',
                    params: {
                      slug: bannerActu.slug,
                      id: bannerActu.id
                    }
                  }).href
                "
                >en savoir plus</v-btn
              >
              <v-btn
                class="ml-4"
                depressed
                outlined
                plain
                rounded
                :to="{ name: 'actualities' }"
                >les actus</v-btn
              >
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <ContainerBackgroundGrey
      v-bind:title="'La troupe'"
      v-bind:subTitle="
        'Initié en 2013 par Chloé de Broca et Félix Beaupérin, le Théâtre du Roi de Coeur prit forme avec l\'élaboration d\'un festival de théâtre en plein air en milieu rural...'
      "
      v-bind:btnTitle="'en savoir plus'"
      v-bind:imgUrl="organisation.image"
      v-bind:urlItem="false"
      v-bind:url="{ slug: bannerFestival.slug, id: bannerFestival.id }"
      v-bind:secondUrl="'about'"
    />

    <v-container class="mediumPadding">
      <v-row align="center" justify="center">
        <v-col cols="12" class="text-center">
          <h3 class="text-h2">Quelques chifres</h3>
          <BlackLine />
        </v-col>
        <v-col
          v-for="(n, index) in numbers"
          :key="index"
          cols="12"
          sm="6"
          md="4"
          class="pa-10"
        >
          <div class="text-center relative">
            <h4 class="text-h3" style="color:#F9BD46B3;" v-text="n.number"></h4>
            <p
              class="absolute text-subtitle-1 font-weight-bold"
              v-text="n.title"
            ></p>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div class="mediumPadding">
      <v-container class="text-center">
        <h3 class="text-h2">Nous soutenir</h3>
        <BlackLine />
        <p>Endossez le statut de mécène !</p>
        <p>Que vous soyez une entreprise ou un particulier, il vous est possible de nous adresser vos dons et d'aider notre compagnie à grandir.</p>
        <v-btn
          plain
          rounded
          class="primary"
          elevation="2"
          href="https://www.helloasso.com/associations/theatre-du-roi-de-coeur/formulaires/5"
          target="_blank"
        >nous soutenir</v-btn>
      </v-container>
      <CardsSlider v-bind:howManyComent="6" />
    </div>

    <div class="bigPadding text-center py-15">
      <h3 class="text-h2">Nos Partenaires</h3>
      <BlackLine />
      <PartnersSlider />
      <v-container v-if="othersPartners !== ''" class="d-flex justify-center">
        <h5 class="text-h5 primary--text">Et aussi</h5>
        <div class="ml-10 text-left text-body-1" v-html="othersPartners"></div>
      </v-container>
    </div>
  </div>
</template>

<script>
import ContainerBackgroundGrey from "../components/containerBackgroundGrey";
import CardsSlider from "../components/slider/cardsSlider"
import PartnersSlider from "../components/slider/partnersSlider"
import BlackLine from "../assets/icos/BlackLine";
import { APIService } from "../APIService";
const apiService = new APIService();
import moment from "moment";

export default {
  name: "home",

  components: {
    ContainerBackgroundGrey,
    BlackLine,
    CardsSlider,
    PartnersSlider
  },

  data: () => ({
    numbers: [
      {
        number: 11,
        title: "Édition de notre festival"
      },
      {
        number: 0,
        title: "Spectateurs en 10 ans"
      },
      {
        number: 32,
        title: "Spectacles créés"
      },
      {
        number: 0,
        title: "Adhérents"
      },
      {
        number: 0,
        title: "Bénévoles"
      }
    ],
    organisation: {},
    bannerFestival: {},
    bannerActu: {},
    apipath: apiService.APIurl(),
    othersPartners: ""
  }),

  mounted() {
    apiService.getAll("acf/v3/options/options").then(data => {
      this.organisation.image = data?.acf?.informations_groupe.image;
      this.othersPartners = data?.acf?.actualites_mises_en_avant.and_others_partners
      apiService
        .getById("wp/v2/posts", data?.acf?.actualites_mises_en_avant.banner_one)
        .then(response => {
          this.bannerFestival = response;
          this.bannerFestival.titleRewriting =
            data?.acf?.actualites_mises_en_avant.article_title_one;
        });
      apiService
        .getById("wp/v2/posts", data?.acf?.actualites_mises_en_avant.banner_two)
        .then(response => {
          this.bannerActu = response;
          this.bannerActu.titleRewriting =
            data?.acf?.actualites_mises_en_avant.article_title_two;
        });
      this.numbers[0].number =
        data?.acf?.actualites_mises_en_avant.edition_number;
      this.numbers[1].number =
        data?.acf?.actualites_mises_en_avant.visitors_number;
      this.numbers[2].number = data?.acf?.actualites_mises_en_avant.plays_number;
      this.numbers[3].number =
        data?.acf?.actualites_mises_en_avant.members_number;
      this.numbers[4].number =
        data?.acf?.actualites_mises_en_avant.volunteers_number;
    });
  },

  methods: {
    returnText(text) {
      var cutlink = text.indexOf("<a class");
      return text.slice(0, cutlink);
    },
    dateFormat(data, format) {
      return moment(String(data))
        .utc()
        .locale("fr")
        .format(format);
    },
    formatDate(start, end) {
      if (this.dateFormat(start, "MMMM") === this.dateFormat(end, "MMMM")) {
        return (
          "Du " +
          this.dateFormat(start, "D") +
          " au " +
          this.dateFormat(end, "D MMMM YYYY")
        );
      } else {
        return (
          "Du " +
          this.dateFormat(start, "D MMMM") +
          " au " +
          this.dateFormat(end, "D MMMM YYYY")
        );
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bigPadding {
  margin: 150px auto 150px auto;
}
.mediumPadding {
  margin: 100px auto 100px auto;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
  top: 20%;
  width: 100%;
}

// .alertDon{
//   background-color: darkblue;
// }
</style>
